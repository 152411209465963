<template>
    <div class="wrapper error">
        <div class="white-box">
            <h1>{{$t('error-msg-header')}}</h1>

            <h3>{{$t('error-please-retry')}}</h3>

            <button class="yellowBtn">
                {{$t('error-reload')}}
                <font-awesome-icon icon="fa-solid fa-arrow-rotate-right" />    
            </button>
        </div>
    </div>
</template>
<script>
export default {
    mounted() {
        
        this.$store.commit('setSkyboxTitle', 'Hiba történt')
        this.$store.commit('setSkyboxText', 'Aliquam sed quam vel massa mollis iaculis a ultricies tellus. Proin commodo lacinia erat. Fusce cursus dictum dui, et bibendum elit luctus et.')
        this.$store.commit('setSkyboxLink', '')
        this.$store.commit('setSkyboxLinkText', '')
    }
}</script>